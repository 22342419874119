import styled, { css } from 'styled-components';
import rightArrow from '../../../images/right-arrow.svg';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

const linkArrow = css`
  padding-right: 25px;
  position: relative;

  &::after {
    content: url(${rightArrow});
    position: absolute;
    right: 0;
    top: 1px;
    filter: invert(15%) sepia(73%) saturate(4470%) hue-rotate(234deg)
      brightness(116%) contrast(111%);
    transition: transform 0.2s;
  }

  &:hover {
    text-decoration: none;
    color: var(--primary-color);

    &::after {
      transform: translateX(8px);
    }
  }
`;

export default styled.div`
  position: relative;
  z-index: 99;
  margin-bottom: ${props => (props.pricing ? '16px' : '24px')};
  margin-bottom: ${props => (props.horizontal || props.hasTags) && '0'};
  margin-bottom: ${props => props.form && '32px'};

  padding-bottom: ${props => props.pricing && !props.horizontal && '24px'};
  border-bottom: ${props => props.active && '1px solid #2C3641'};

  &:last-of-type {
    padding-right: ${props => props.horizontal && '30px'};
    border-right: ${props => props.horizontal && '1px solid #f0f2f5'};
  }

  margin-right: ${props => props.horizontal && '30px'};

  .list-heading-container {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.hasTags && '8px'};

    &:empty {
      margin-top: -0.025rem;

      ${atMinWidth.sm`
        margin-top: -0.15rem;
      `}
    }

    .list-heading {
      line-height: 22px;
      text-transform: capitalize;
    }

    .list-tag-label {
      ${font('overline', 'md', '900')}
      padding: 6px 12px;
      background-color: var(--accent-color);
      border-radius: 2px;
    }
  }

  .list-item {
    display: ${props => (props.grid ? 'inline-grid' : 'flex')};
    align-items: ${props => (props.pricing ? 'center' : 'start')};
    margin-bottom: ${props => (props.pricing ? '12px' : '24px')};
    margin-bottom: ${props => props.spaceBetween && '72px'};
    width: ${props => (props.grid ? '33.333%' : '100%')};
    @media (max-width: 768px) {
      width: 100%;
    }
    .list-subhead {
      font-size: 16.2px;
      line-height: 140%;
      max-width: ${props => (props.grid ? '165px' : '100%')};
      @media (max-width: 768px) {
        max-width: 100%;
      }
      a {
        font-weight: 600;
        margin-top: ${props => (props.grid ? '20px' : '0')};
        display: inline-block;
        ${props => props.grid && linkArrow};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  figure {
    display: ${props => (props.grid ? 'inline-block' : 'flex')};
    background: ${props => (props.grid ? '#E9F2FF' : 'transparent')};
    border-radius: ${props => (props.grid ? '50%' : '0')};
    align-items: start;
    margin-right: ${props => (props.grid ? '0' : '16px')};
    margin-bottom: ${props => (props.grid ? '20px' : '0')};
    width: ${props => (props.grid ? '56px' : '20px')};
    height: ${props => (props.grid ? '56px' : '100%')};
    position: relative;
    z-index: 1;
    text-align: center;
    img {
      align-items: start;
      width: ${props => (props.grid ? '24px' : '20px')};
      height: ${props => (props.grid ? '100%' : '20px')};
    }
  }

  .gray-icon {
    img {
      filter: ${props => props.active && 'grayscale(1)'};
    }
  }

  .print-container {
    height: max-content;
    line-height: 1;
  }

  .list-heading {
    ${font('text', 'sm', '700')}
    color: ${props => (props.lightNav ? colors.base.white : colors.gray[900])};
    margin-bottom: 4px;
    margin-right: 18px;

    ${atMinWidth.sm`
      ${font('text', 'md', '700')}
      margin-bottom: 4px;
    `}
  }

  .list-subhead {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${props => (props.grid ? '155px' : '100%')};
    height: max-content;

    @media (max-width: 768px) {
      min-height: 100%;
    }

    color: ${props => (props.lightNav ? colors.gray[100] : colors.gray[600])};

    p {
      ${font('text', 'sm', '400')}

      ${atMinWidth.sm`
        ${font('text', 'md', '400')}
      `}
    }
  }
`;
