import React from 'react';
import ListContainerStyles from '../../styles/Components/ListItems/ListContainerStyles';
import Img from '../../utils/OptimizedImage';
import { formatTelephoneNumber } from '../../utils/TelReplacer';

const PriceSwiperList = ({
  items,
  pricing,
  horizontal,
  active,
  lightNav,
  form,
  spaceBetween,
  hasTags,
  noSubhead,
  className,
}) => (
  <ListContainerStyles
    pricing={pricing}
    horizontal={horizontal}
    active={active}
    lightNav={lightNav}
    form={form}
    spaceBetween={spaceBetween}
    hasTags={hasTags}
    className={className}
  >
    {items.map(item => (
      <div className="list-item" key={item.id}>
        {item?.icon?.image && (
          <figure
            className={`list-icon ${item.icon.internalName ===
              'Blue Minus Icon' && 'gray-icon'}`}
          >
            {item?.icon?.image?.gatsbyImageData ? (
              <Img
                image={item.icon.image.gatsbyImageData}
                alt={`Decorative Icon - ${item.icon.internalName}`}
              />
            ) : (
              <Img
                src={item.icon.image.url}
                alt={`Decorative Icon - ${item.icon.internalName}`}
              />
            )}
          </figure>
        )}
        <div className="print-container">
          <div className="list-heading-container">
            {item?.heading && (
              <p
                className="list-heading"
                style={{
                  fontFamily: !item?.subhead && "'CircularXX', sans-serif",
                }}
              >
                {item.heading}
              </p>
            )}
            {item?.tagLabel && (
              <div className="list-tag-label">{item.tagLabel}</div>
            )}
          </div>
          {item?.subhead && item.heading.toLowerCase() !== 'call us' && (
            <div
              dangerouslySetInnerHTML={{ __html: item.subhead }}
              className={`list-subhead ${noSubhead ? 'd-none' : ''}${
                item?.icon?.internalName === 'Green Check Icon'
                  ? 'text-color'
                  : ''
              }`}
            />
          )}
          {item?.subhead && item.heading.toLowerCase() === 'call us' && (
            <div className="list-subhead">
              <a href={`tel:${formatTelephoneNumber(item.subhead)}`}>
                {item.subhead.replace(/<[^>]+>/gi, '')}
              </a>
            </div>
          )}
        </div>
      </div>
    ))}
  </ListContainerStyles>
);

export default PriceSwiperList;
